import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, Badge, DropdownItem, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { GetTransactionsOfUser } from "../../../network/apis/transactions";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/Utils";

const Invoices = () => {
  const [data, setData] = useState([]);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("asc");

  const { user } = useSelector((state) => state.user);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPayments = async (userId) => {
    setLoading(true);
    try {
      const res = await GetTransactionsOfUser(userId);
      if (res.result === "success") {
        setPayments(res.transactions);
        setData(res.transactions);
      } else {
        toast.error(res?.message ?? "Error fetching payments");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message ?? "Error fetching payments");
    }
  };

  useEffect(() => {
    if (user?.uid) {
      fetchPayments(user.uid);
    }
  }, [user]);

  // Sorting data
  const sortFunc = () => {
    let defaultData = payments;
    if (sort === "dsc") {
      // sort data by date
      let sortedData = defaultData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData([...sortedData]);
    } else if (sort === "asc") {
      // sort data by date
      let sortedData = defaultData.sort((a, b) => new Date(a.date) - new Date(b.date));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = payments.filter((item) => {
        return item.paymentId.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...payments]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  return (
    <React.Fragment>
      <Head title="Order History"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span>Order History</span>
          </div>
          <BlockBetween size="md" className="g-4">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Invoices
              </BlockTitle>
              <BlockDes>
                <p>You can find all of your order</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools gx-3">
                <li>
                  <a href="/#" className="btn btn-white btn-dim btn-outline-primary">
                    <Icon name="download-cloud" />
                    <span>
                      <span className="d-none d-sm-inline-block">Get</span> Statement
                    </span>
                  </a>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered card-stretch">
            {loading ? (
              <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
                <Spinner size="lg" color="primary" />
              </section>
            ) : (
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">All Invoices</h5>
                    </div>
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="setting"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-check">
                                <li>
                                  <span>Show</span>
                                </li>

                                <li className={itemPerPage === 10 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(10);
                                    }}
                                  >
                                    10
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 15 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(15);
                                    }}
                                  >
                                    15
                                  </DropdownItem>
                                </li>
                              </ul>
                              <ul className="link-check">
                                <li>
                                  <span>Order</span>
                                </li>
                                <li className={sort === "dsc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("dsc");
                                      sortFunc("dsc");
                                    }}
                                  >
                                    DESC
                                  </DropdownItem>
                                </li>
                                <li className={sort === "asc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("asc");
                                      sortFunc("asc");
                                    }}
                                  >
                                    ASC
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="Search by Order Id"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <table className="table table-orders">
                    <thead className="tb-odr-head">
                      <tr className="tb-odr-item">
                        <th className="tb-odr-info">
                          <span className="tb-odr-id">Payment Id</span>
                          <span className="tb-odr-date d-none d-md-inline-block">Date</span>
                        </th>
                        <th className="tb-odr-amount">
                          <span className="tb-odr-total">Amount</span>
                          <span className="tb-odr-status d-none d-md-inline-block">Status</span>
                        </th>
                        <th className="tb-odr-action">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="tb-odr-body">
                      {currentItems.length > 0
                        ? currentItems.map((item) => {
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td className="tb-odr-info">
                                  <span className="tb-odr-id">
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/invoice-details/${item.paymentId}`}
                                    >
                                      #{item.paymentId}
                                    </Link>
                                  </span>
                                  <span className="tb-odr-date">{formatDate(item.date, "DD-MM-YYYY")}</span>
                                </td>
                                <td className="tb-odr-amount">
                                  <span className="tb-odr-total">
                                    <span className="amount">${item.amount}</span>
                                  </span>
                                  <span className="tb-odr-status">
                                    <Badge
                                      color={
                                        item.status === "paid"
                                          ? "success"
                                          : item.status === "pending"
                                          ? "warning"
                                          : "danger"
                                      }
                                      className="badge-dot"
                                    >
                                      {item.status}
                                    </Badge>
                                  </span>
                                </td>
                                <td className="tb-odr-action">
                                  <div className="tb-odr-btns d-none d-sm-inline">
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/invoice-print/${item.id}`}
                                      target="_blank"
                                    >
                                      <Button color="primary" size="sm" className="btn-icon btn-white btn-dim">
                                        <Icon name="printer-fill"></Icon>
                                      </Button>
                                    </Link>
                                    <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                                      <Button color="primary" size="sm" className="btn btn-dim">
                                        View
                                      </Button>
                                    </Link>
                                  </div>
                                  <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                                    <Button className="btn-pd-auto d-sm-none">
                                      <Icon name="chevron-right"></Icon>
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="card-inner">
                  {currentItems.length > 0 ? (
                    <PaginationComponent
                      noDown
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Invoices;
