import React, { useState } from "react";
import LoginForm from "./v2/LoginForm";
import RegisterForm from "./v2/RegisterForm";
import PasswordResetForm from "./v2/PasswordResetForm";

function AuthModel({ hideModal = () => {} }) {
  const [showLogin, setShowLogin] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  return (
    <section>
      {showLogin && (
        <LoginForm
          hideModal={hideModal}
          showReset={() => {
            setShowLogin(false);
            setShowSignup(false);
            setShowPasswordReset(true);
          }}
          showSignup={() => {
            setShowSignup(true);
            setShowLogin(false);
            setShowPasswordReset(false);
          }}
        />
      )}

      {showSignup && (
        <RegisterForm
          hideModal={hideModal}
          showLogin={() => {
            setShowLogin(true);
            setShowSignup(false);
            setShowPasswordReset(false);
          }}
        />
      )}

      {showPasswordReset && (
        <PasswordResetForm
          showSignIn={() => {
            setShowLogin(true);
            setShowSignup(false);
            setShowPasswordReset(false);
          }}
        />
      )}
    </section>
  );
}

export default AuthModel;
