import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Modal, ModalBody, Spinner } from "reactstrap";
import { svgData } from "../../components/crafted-icons/NioIconData";
import { CancelTransaction, GetTransactionsOfUserCurrentMonth } from "../../../network/apis/transactions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SubscriptionDetailsCard from "../../../components/subscription/SubscriptionDetailsCard";

const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const SubscriptionDetails = () => {
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [subscriptionCancelConfirmedModal, setSubscriptionCancelConfirmedModal] = useState(false);

  const { user } = useSelector((state) => state.user);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [fetchingCurrentPlan, setFetchingCurrentPlan] = useState(false);

  const getCurrentPlan = async () => {
    try {
      setFetchingCurrentPlan(true);
      const res = await GetTransactionsOfUserCurrentMonth(user.uid);

      if (res.result === "success" && res.transactions) {
        setActiveSubscriptions(res.transactions);
      } else {
        setActiveSubscriptions([]);
      }
      setFetchingCurrentPlan(false);
    } catch (error) {
      toast.error("Failed to get current plan. Please try again later.");
      setActiveSubscriptions([]);
      setFetchingCurrentPlan(false);
    }
  };

  useEffect(() => {
    getCurrentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [confrimationText, setConfrimationText] = useState("");
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  const cancelSubscription = async (paymentId) => {
    setCancellingSubscription(true);

    try {
      const res = await CancelTransaction(paymentId);
      if (res.result === "success") {
        toast.success("Payment canceled successfully");
        setSubscriptionCancelConfirmedModal(true);
        setSubscriptionCancelModal(false);
      } else {
        toast.error(res?.message ?? "Error canceling payment");
      }
      setCancellingSubscription(false);
    } catch (error) {
      setCancellingSubscription(false);
      toast.error(error?.message ?? "Error canceling payment");
    }
  };

  return (
    <React.Fragment>
      <Head title="My Subscription"></Head>
      {fetchingCurrentPlan ? (
        <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
          <Spinner size="lg" color="primary" />
        </section>
      ) : (
        <>
          {activeSubscriptions?.length > 0 ? (
            <Content>
              <BlockHead size="lg">
                <div className="nk-block-head-sub">
                  <span>Manage Subscriptions</span>
                </div>
                <BlockBetween size="md" className="g-4">
                  <BlockHeadContent>
                    <BlockTitle tag="h2" className="fw-normal">
                      My Subscriptions
                    </BlockTitle>

                    <BlockDes>
                      <p>
                        Here is list of package/product that you have subscribed.{" "}
                        <span className="text-primary">
                          <Icon name="info" />
                        </span>
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <ul className="nk-block-tools justify-content-md-end g-4 flex-wrap">
                      <li className="order-md-last">
                        <button
                          className="btn btn-auto btn-dim btn-danger"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setSubscriptionCancelModal(true);
                          }}
                        >
                          <Icon name="cross"></Icon>
                          <span>Cancel Subscription</span>
                        </button>
                      </li>
                    </ul>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <Row>
                  <Col xl="12">
                    {activeSubscriptions.map((subscription, index) => (
                      <SubscriptionDetailsCard key={index} currentPlanDetails={subscription} />
                    ))}
                  </Col>
                </Row>
              </Block>

              <Block>
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="nk-help">
                      <div className="nk-help-img">{helpSupportIcon.svg}</div>
                      <div className="nk-help-text">
                        <h5>We’re here to help you!</h5>
                        <p className="text-soft">
                          Ask a question or file a support ticket or report an issues. Our team support team will get
                          back to you by email.
                        </p>
                      </div>
                      <div className="nk-help-action">
                        <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-lg btn-outline-primary">
                          Get Support Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Card>
              </Block>

              <Modal isOpen={subscriptionCancelModal} size="md" toggle={() => setSubscriptionCancelModal(false)}>
                <button
                  className="close"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionCancelModal(false);
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </button>
                <div className="modal-body modal-body-md">
                  <h4 className="nk-modal-title title">Cancel Your Subscription</h4>
                  <p>
                    <strong>Are you sure you want to cancel your subscription?</strong>
                  </p>
                  <p>
                    If you cancel, you'll lose your all access. But you can re-subscription your favourite plan any
                    time.
                  </p>
                  <div className="form">
                    <div className="form-group">
                      <label className="form-label">
                        To cancel your subscription, please enter <b>cancel subscription</b> below.
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type here..."
                          onChange={(ev) => {
                            setConfrimationText(ev.target.value);
                          }}
                        />
                      </div>
                      <div className="form-note">
                        <span>You'll receieve confirmation email once successfully cancel your plan.</span>
                      </div>
                    </div>
                    <ul className="align-center flex-wrap g-3">
                      <li>
                        <button
                          className="btn btn-primary"
                          disabled={cancellingSubscription}
                          onClick={(ev) => {
                            ev.preventDefault();
                            if (confrimationText.toLowerCase() === "cancel subscription") {
                              setCancellingSubscription(true);
                              cancelSubscription();
                            } else {
                              toast.error("Please enter correct text to cancel subscription.");
                            }
                          }}
                        >
                          Cancel Subscription
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn btn-light"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setSubscriptionCancelModal(false);
                          }}
                        >
                          Never mind, don't cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={subscriptionCancelConfirmedModal}
                toggle={() => setSubscriptionCancelConfirmedModal(false)}
              >
                <ModalBody className="modal-body-md text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success-dim text-success"></em>
                    <h4 className="nk-modal-title">Successfully Cancelled</h4>
                    <div className="nk-modal-text">
                      <p>
                        We sent you a confirmation email <strong>(this may take some time to receive)</strong>.
                      </p>
                    </div>
                    <div className="nk-modal-action-lg">
                      <button
                        href="#"
                        className="btn btn-mw btn-light"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setSubscriptionCancelConfirmedModal(false);
                        }}
                      >
                        Return
                      </button>
                    </div>
                  </div>
                </ModalBody>
                <div className="modal-footer bg-lighter">
                  <div className="text-center w-100">
                    <p>You can easily re-subscription your favourite plan any time.</p>
                  </div>
                </div>
              </Modal>
            </Content>
          ) : (
            <>
              <Content>
                <BlockHead size="lg">
                  <div className="nk-block-head-sub">
                    <span>Subscription</span>
                  </div>
                  <BlockBetween size="md" className="g-4">
                    <BlockHeadContent>
                      <BlockTitle tag="h2" className="fw-normal">
                        No Active Subscription
                      </BlockTitle>
                      <BlockDes>
                        <p>
                          You don't have any active subscription. Please{" "}
                          <Link to={`${process.env.PUBLIC_URL}/pricing`}>subscribe</Link> to any available plan.
                        </p>
                      </BlockDes>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
              </Content>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default SubscriptionDetails;
