import React, { useEffect, useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/slices/UserSlice";

const User = () => {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      themeUpdate?.skin(theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <div className="user-toggle">
            <UserAvatar
              className="sm"
              image={user?.photoURL ?? ""}
              imageAlt={user?.name ?? ""}
              text={user?.name ? user.name.slice(0, 1).toUpperCase() + user.name.slice(1, 2).toUpperCase() : ""}
            />
            <div className="user-name dropdown-indicator d-none d-sm-block">{user?.displayName ?? ""}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>
                <UserAvatar
                  className="sm"
                  image={user?.photoURL ?? ""}
                  imageAlt={user?.name ?? ""}
                  text={user?.name ? user.name.slice(0, 1).toUpperCase() + user.name.slice(1, 2).toUpperCase() : ""}
                />
              </span>
            </div>
            <div className="user-info">
              <span className="lead-text">{user?.name ?? ""}</span>
              <span
                className="sub-text"
                style={{
                  wordBreak: "break-all",
                }}
              >
                {user?.email ?? ""}
              </span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/profile" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                  localStorage.setItem("theme", theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <div className="d-flex align-items-center me-5">
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center  me-5">
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </div>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                dispatch(logout());
              }}
            >
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
