import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Col, Modal, Row, Spinner } from "reactstrap";
import { formatDate } from "../../utils/Utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CancelTransaction } from "../../network/apis/transactions";
import { post } from "../../network/Config/apiService";

function SubscriptionCard({ item, refreshUI }) {
  const { user } = useSelector((state) => state.user);

  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  const getSubscriptionEndData = (joinDate, planDuration = 1) => {
    const date = new Date(joinDate);
    const nextBillingDate = new Date(date.setMonth(date.getMonth() + planDuration));

    return nextBillingDate.toDateString();
  };

  const getTotalPaidAmount = () => {
    const currency = item?.planData?.currency;
    let totalAmount = 0;

    if (currency === "IRR") {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotTomansPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotTomansPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    } else {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotUsdPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotUsdPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    }

    return `${currency === "IRR" ? "IRR" : "USD"} ${Number(totalAmount).toLocaleString()}`;
  };

  const [isUpgrading, setIsUpgrading] = useState(false);

  const getAmountToUpgrade = () => {
    const planData = item?.planData;
    const duration = planData?.duration;

    let paidAmount = planData?.withoutBotTomansPrice * duration;

    const totalWithBot = (planData?.withBotTomansPrice ?? 0) * duration;

    return totalWithBot - paidAmount;
  };

  const getAmountToShow = () => {
    const planData = item?.planData;
    const duration = planData?.duration;
    const currency = planData?.currency;

    if (currency === "IRR") {
      let paidAmount = planData?.withoutBotTomansPrice * duration;
      const totalWithBot = (planData?.withBotTomansPrice ?? 0) * duration;
      return totalWithBot - paidAmount;
    } else {
      let paidAmount = planData?.withoutBotUsdPrice * duration;
      const totalWithBot = (planData?.withBotUsdPrice ?? 0) * duration;
      return totalWithBot - paidAmount;
    }
  };

  const upgradeSubscription = async () => {
    const planData = item?.planData;
    setIsUpgrading(true);

    post(`/payment/request`, {
      amount: getAmountToUpgrade(),
      amountToShow: getAmountToShow(),
      description: `Upgrading ${planData.title} plan`,
      callbackUrl: `${window.location.origin}/pricing`,
      user: {
        uid: user.uid,
        name: user.fullName,
        email: user.email,
        phone: user.phone,
      },
      planData: {
        ...planData,
        planType: planData.planType,
        currency: planData.currency,
        isBotIncluded: true,
        isUpgrade: true,
        paymentToUpgrade: item.paymentId,
      },
    })
      .then((response) => {
        setIsUpgrading(false);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        setIsUpgrading(false);
        toast.error(error?.response?.data?.message ?? "Something went wrong. Please try again later.");
      });
  };

  const renewSubscription = async () => {
    const planData = item?.planData;
    setIsUpgrading(true);

    post(`/payment/request`, {
      amount: item?.amount,
      amountToShow: item?.amountToShow,
      description: `Renewing ${planData.title} plan`,
      callbackUrl: `${window.location.origin}/pricing`,
      user: {
        uid: user.uid,
        name: user.fullName,
        email: user.email,
        phone: user.phone,
      },
      planData: {
        ...planData,
        planType: planData.planType,
        currency: planData.currency,
        isBotIncluded: planData.isBotIncluded,
        isRenew: true,
        paymentToRenew: item.paymentId,
      },
    })
      .then((response) => {
        setIsUpgrading(false);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        setIsUpgrading(false);
        toast.error(error?.response?.data?.message ?? "Something went wrong. Please try again later.");
      });
  };

  const cancelSubscription = async () => {
    setCancellingSubscription(true);

    try {
      const res = await CancelTransaction(item?.paymentId);
      if (res.result === "success") {
        toast.success("Subscription canceled successfully");
        setSubscriptionCancelModal(false);
      } else {
        toast.error(res?.message ?? "Error canceling subscription");
      }
      setCancellingSubscription(false);
    } catch (error) {
      setCancellingSubscription(false);
      toast.error(error?.message ?? "Error canceling subscription");
    } finally {
      refreshUI();
    }
  };

  return (
    <>
      <Card className="card-bordered sp-plan">
        <Row className="no-gutters">
          <Col md="8">
            <div className="sp-plan-info card-inner">
              <Row className="gx-0 gy-3">
                <Col sm="12">
                  <div className="sp-plan-name">
                    <h6 className="title">
                      <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                        {item?.planData?.title}{" "}
                        <Badge
                          color={item.status?.toLowerCase() === "paid" ? "success" : "danger"}
                          className="rounded-pill"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item.status}
                        </Badge>
                      </Link>
                    </h6>
                    <p>
                      Subscription ID: <span className="text-base">{item?.paymentId}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sp-plan-desc card-inner">
              <Row className="gx-1 gy-1">
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Started On</span> {formatDate(item?.date, "DD MMM YYYY")}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Expires On</span>{" "}
                    {getSubscriptionEndData(item?.date, item?.planData?.duration)}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Price</span>
                    {getTotalPaidAmount()}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Duration </span>
                    {item?.planData?.duration} {item?.planData?.duration > 1 ? "Months" : "Month"}
                  </p>
                </Col>
                {item?.planData?.isBotOptionAvailable && (
                  <Col col="6" lg="3">
                    <p>
                      <span className="text-soft">Strategy Bot Access</span>{" "}
                      {item?.planData?.isBotIncluded ? "Yes" : "No"}
                    </p>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md="4">
            <div className="sp-plan-action card-inner">
              {item.status?.toLowerCase() === "paid1" &&
                item?.planData?.isBotOptionAvailable &&
                !item?.isBotIncluded && (
                  <>
                    <div className="sp-plan-btn">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          upgradeSubscription();
                        }}
                        disabled={isUpgrading}
                      >
                        <span>
                          {isUpgrading ? (
                            <Spinner
                              size="sm"
                              color="light"
                              style={{
                                marginRight: "20px",
                                marginLeft: "20px",
                              }}
                            />
                          ) : (
                            "Upgrade Plan"
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="sp-plan-note text-md-center">
                      <p>
                        Next Billing on{" "}
                        <span>{item?.date && getSubscriptionEndData(item.date, item.planData.duration)}</span>
                      </p>
                    </div>
                  </>
                )}
              {item.status?.toLowerCase() === "expired" && (
                <>
                  <div className="sp-plan-btn">
                    <button
                      className="btn btn-dim btn-white btn-outline-primary"
                      onClick={() => {
                        renewSubscription();
                      }}
                      disabled={isUpgrading}
                    >
                      {isUpgrading ? (
                        <Spinner
                          size="sm"
                          color="light"
                          style={{
                            marginRight: "20px",
                            marginLeft: "20px",
                          }}
                        />
                      ) : (
                        "Renew Plan"
                      )}
                    </button>
                  </div>
                  <div className="sp-plan-note text-md-center">
                    <p>You can renew the plan anytime.</p>
                  </div>
                </>
              )}
              <button
                className="btn btn-link text-danger mt-1"
                onClick={() => {
                  setSubscriptionCancelModal(true);
                }}
              >
                Cancel Subscription
              </button>
            </div>
          </Col>
        </Row>
      </Card>

      <Modal isOpen={subscriptionCancelModal} size="md" toggle={() => setSubscriptionCancelModal(false)}>
        <button
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            setSubscriptionCancelModal(false);
          }}
        >
          <em className="icon ni ni-cross"></em>
        </button>
        <div className="modal-body modal-body-md">
          <h4 className="nk-modal-title title">Cancel Your Subscription</h4>
          <p>
            <strong>Are you sure you want to cancel your subscription?</strong>
          </p>
          <p>If you cancel, you'll lose your all access. But you can re-subscription your favourite plan any time.</p>
          <div className="form">
            <ul className="align-center flex-wrap g-3">
              <li>
                <button
                  className="btn btn-primary"
                  disabled={cancellingSubscription}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setCancellingSubscription(true);
                    cancelSubscription();
                  }}
                >
                  {cancellingSubscription ? <Spinner size="sm" color="light" /> : "Yes, Cancel Subscription"}
                </button>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionCancelModal(false);
                  }}
                >
                  Never mind, don't cancel
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SubscriptionCard;
