import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Col,
  Row,
} from "../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, Spinner } from "reactstrap";
import { svgData } from "../../components/crafted-icons/NioIconData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GetAllPricePlans, GetTransactionsOfUserCurrentMonth } from "../../../network/apis/transactions";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../../redux/slices/CartSlice";
import { post } from "../../../network/Config/apiService";

const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);
  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [pricingTableData, setPricingTableData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);
  const [currentPlans, setCurrentPlans] = useState("");
  const [isBotSelected, setIsBotSelected] = useState(false);
  const [currency, setCurrency] = useState("USD");

  const selectPlan = async ({ planData }) => {
    dispatch(
      addItemToCart({
        item: {
          ...planData,
          currency: currency,
          isBotIncluded: isBotSelected ? true : false,
          duration: 1,
        },
      })
    );

    navigate(`/cart`);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get("Status");
    const amount = params.get("amount");
    const payment_id = params.get("payment_id");
    const authority = params.get("Authority");
    const description = params.get("description");

    if (amount && payment_id && authority && description) {
      setSelectedPlan(description);
      setRequestingPaymentLink(true);

      if (status === "OK") {
        // Add toast with promise
        toast
          .promise(
            post(`/payment/verify`, {
              amount: amount,
              payment_id: payment_id,
              authority: authority,
            }),
            {
              pending: "Verifying payment...",
              success: {
                render() {
                  return "Payment successful. Your plan has been updated.";
                },
              },
              error: {
                render({ data }) {
                  return `${data?.response?.data?.message ?? "Payment failed. Please try again later."}`;
                },
              },
            }
          )
          .finally(() => {
            setSelectedPlan("");
            setRequestingPaymentLink(false);
            navigate(`/pricing`);
            getCurrentPlan();
          });
      } else if (status === "NOK") {
        toast.error("Payment failed. Please try again later.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentPlan = async () => {
    try {
      const res = await GetTransactionsOfUserCurrentMonth(user.uid);

      if (res.result === "success" && res.transactions) {
        setCurrentPlans(
          res.transactions.map((transaction) => {
            return transaction?.planData?.id;
          })
        );
      } else {
        toast.error(res.message);
        setCurrentPlans([]);
      }
    } catch (error) {
      toast.error("Failed to get current plan. Please try again later.");
    }
  };

  const getPricingTables = async () => {
    try {
      setFetchingPlans(true);
      const res = await GetAllPricePlans();
      if (res.status === "success") {
        setPricingTableData(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message ?? "Failed to fetch price plans. Please try again later.");
    } finally {
      setFetchingPlans(false);
    }
  };

  useEffect(() => {
    getPricingTables();
    getCurrentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (language === "fa") {
      setCurrency("IRR");
    } else {
      setCurrency("USD");
    }
  }, [language]);

  return (
    <React.Fragment>
      <Head title="Pricing"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span>Pricing</span>
          </div>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Choose Suitable Plan
              </BlockTitle>
              <BlockDes>
                <p>You can change your plan any time by upgrade your plan</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {fetchingPlans ? (
          <>
            <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
              <Spinner size="lg" color="primary" />
            </section>
          </>
        ) : (
          <>
            <Block>
              <Row className="g-gs justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-soft mb-0 me-2">Strategy without bot</p>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isBotSelected"
                      defaultChecked={isBotSelected ? true : false}
                      onChange={(e) => setIsBotSelected(e.target.checked)}
                    />
                    <label className="custom-control-label text-soft" htmlFor="isBotSelected"></label>
                  </div>
                  <p className="text-soft">Strategy along with bot</p>
                </div>
              </Row>
            </Block>

            <Block>
              <Row className="g-gs">
                {pricingTableData
                  .filter((item) => !item?.isForFarsiUsers)
                  .filter((item) => item?.isBotOptionAvailable)
                  .map((item) => {
                    return (
                      <Col md="4" key={item?.id}>
                        <Card className={`card-bordered pricing text-center`}>
                          {currentPlans?.includes(item?.id) ? (
                            <Badge color="primary" className="pricing-badge">
                              Active
                            </Badge>
                          ) : item?.isPopular ? (
                            <Badge color="primary" className="pricing-badge">
                              Popular
                            </Badge>
                          ) : (
                            <></>
                          )}

                          <div className="pricing-body">
                            <div className="pricing-title w-220px mx-auto">
                              <h5 className="title">{item?.title}</h5>
                              <span className="sub-text">{item?.description}</span>
                            </div>
                            <div className="pricing-amount">
                              <div className="amount">
                                {currency === "IRR" ? " تومان" : " $"}
                                {currency === "IRR"
                                  ? isBotSelected
                                    ? item?.withBotTomansPrice
                                    : item?.withoutBotTomansPrice
                                  : isBotSelected
                                  ? item?.withBotUsdPrice
                                  : item?.withoutBotUsdPrice}
                                <span
                                  className="text-soft mx-1"
                                  style={{
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  /monthly
                                </span>
                              </div>
                              <div className="d-flex flex-column justify-content-center align-items-center p-2 border-top mt-2">
                                {item?.features?.map((feature, index) => (
                                  <p key={index} className="text-soft text-center">
                                    {feature}
                                  </p>
                                ))}
                                {isBotSelected &&
                                  item?.extraFeatures?.map((feature, index) => (
                                    <p key={index} className="text-soft text-center">
                                      {feature}
                                    </p>
                                  ))}
                              </div>
                            </div>
                            <div className="pricing-action">
                              <Button
                                color="primary"
                                disabled={
                                  (selectedPlan?.toLowerCase() === item?.title?.toLowerCase() &&
                                    requestingPaymentLink) ||
                                  currentPlans?.includes(item?.id)
                                }
                                onClick={() => {
                                  setSelectedPlan(item?.title);
                                  selectPlan({
                                    amount: isBotSelected
                                      ? item?.withBotTomansPrice?.toString()
                                      : item?.withoutBotTomansPrice?.toString(),
                                    description: item?.title,
                                    callbackUrl: `${window.location.origin}/pricing`,
                                    planData: item,
                                  });
                                }}
                              >
                                {currentPlans.includes(item?.id) ? (
                                  "Already Active"
                                ) : requestingPaymentLink &&
                                  selectedPlan?.toLowerCase() === item?.title?.toLowerCase() ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  "Select Plan"
                                )}
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Block>

            <hr />

            <Block>
              <Row className="g-gs">
                {pricingTableData
                  .filter((item) => !item?.isForFarsiUsers)
                  .filter((item) => !item?.isBotOptionAvailable)
                  .map((item) => {
                    return (
                      <Col md="4" key={item?.id}>
                        <Card className={`card-bordered pricing text-center`}>
                          {currentPlans?.includes(item?.id) ? (
                            <Badge color="primary" className="pricing-badge">
                              Active
                            </Badge>
                          ) : item?.isPopular ? (
                            <Badge color="primary" className="pricing-badge">
                              Popular
                            </Badge>
                          ) : (
                            <></>
                          )}

                          <div className="pricing-body">
                            <div className="pricing-title w-220px mx-auto">
                              <h5 className="title">{item?.title}</h5>
                              <span className="sub-text">{item?.description}</span>
                            </div>
                            <div className="pricing-amount">
                              <div className="amount">
                                {currency === "IRR" ? " تومان" : " $"}
                                {currency === "IRR" ? item?.withoutBotTomansPrice : item?.withoutBotUsdPrice}
                                <span
                                  className="text-soft mx-1"
                                  style={{
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  /monthly
                                </span>
                              </div>
                              <div className="d-flex flex-column justify-content-center align-items-center p-2 border-top mt-2">
                                {item?.features?.map((feature, index) => (
                                  <p key={index} className="text-soft text-center">
                                    {feature}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <div className="pricing-action">
                              <Button
                                color="primary"
                                disabled={
                                  (selectedPlan?.toLowerCase() === item?.title?.toLowerCase() &&
                                    requestingPaymentLink) ||
                                  currentPlans?.includes(item?.id)
                                }
                                onClick={() => {
                                  setSelectedPlan(item?.title);
                                  selectPlan({
                                    amount: item?.withoutBotTomansPrice?.toString(),
                                    description: item?.title,
                                    callbackUrl: `${window.location.origin}/pricing`,
                                    planData: item,
                                  });
                                }}
                              >
                                {currentPlans.includes(item?.id) ? (
                                  "Already Active"
                                ) : requestingPaymentLink &&
                                  selectedPlan?.toLowerCase() === item?.title?.toLowerCase() ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  "Select Plan"
                                )}
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Block>

            <Block>
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="nk-help">
                    <div className="nk-help-img">{helpSupportIcon.svg}</div>
                    <div className="nk-help-text">
                      <h5>We’re here to help you!</h5>
                      <p className="text-soft">
                        Ask a question or file a support ticket or report an issues. Our team support team will get back
                        to you by email.
                      </p>
                    </div>
                    <div className="nk-help-action">
                      <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-lg btn-outline-primary">
                        Get Support Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </Block>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Pricing;
