import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge, Spinner } from "reactstrap";
import { CancelTransaction, GetTransactionsOfUser } from "../../../network/apis/transactions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/Utils";
import { post } from "../../../network/Config/apiService";

const Payments = () => {
  const { user } = useSelector((state) => state.user);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelingPayment, setCancelingPayment] = useState(false);
  const [retryingPayment, setRetryingPayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const fetchPayments = async (userId) => {
    setLoading(true);
    try {
      const res = await GetTransactionsOfUser(userId);
      if (res.result === "success") {
        const sortedTransactions = res.transactions.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        setPayments(sortedTransactions);
      } else {
        toast.error(res?.message ?? "Error fetching payments");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message ?? "Error fetching payments");
    }
  };

  const cancelPayment = async (paymentId) => {
    setCancelingPayment(true);
    try {
      const res = await CancelTransaction(paymentId);
      if (res.result === "success") {
        toast.success("Payment canceled successfully");
        fetchPayments(user.uid);
      } else {
        toast.error(res?.message ?? "Error canceling payment");
      }
      setCancelingPayment(false);
    } catch (error) {
      setCancelingPayment(false);
      toast.error(error?.message ?? "Error canceling payment");
    }
  };

  const retryPayment = async (paymentId) => {
    setRetryingPayment(true);
    post(`/payment/regenerate-request`, {
      uid: user.uid,
      callbackUrl: `${window.location.origin}/pricing`,
      paymentId: paymentId,
    })
      .then((response) => {
        setRetryingPayment(false);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        setRetryingPayment(false);
        toast.error(error?.response?.data?.message ?? "Something went wrong. Please try again later.");
      });
  };

  useEffect(() => {
    if (user?.uid) {
      fetchPayments(user.uid);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Head title="Payment History"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span>Account Billing</span>
          </div>
          <BlockBetween size="md" className="g-4">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Payment History
              </BlockTitle>
              <BlockDes>
                <p>Here is your payment history of account.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            {loading ? (
              <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
                <Spinner size="lg" color="primary" />
              </section>
            ) : (
              <table className="table table-tranx">
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="tb-tnx-id">
                      <span className="">ID</span>
                    </th>
                    <th className="tb-tnx-info">
                      <span className="tb-tnx-desc d-none d-sm-inline-block">
                        <span>Bill For</span>
                      </span>
                      <span className="tb-tnx-date d-md-inline-block d-none">
                        <span className="d-md-none">Date</span>
                        <span className="d-none d-md-block">
                          <span>Issue Date</span>
                        </span>
                      </span>
                    </th>
                    <th className="tb-tnx-amount">
                      <span className="tb-tnx-total">Total</span>
                      <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                    </th>
                    <th className="tb-odr-action">
                      <span className="tb-tnx-status d-none d-md-inline-block">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments.length > 0
                    ? payments.map((item) => {
                        return (
                          <tr key={item.id} className="tb-tnx-item">
                            <td className="tb-tnx-id">
                              <span className="tb-odr-id">
                                <Link to={`${process.env.PUBLIC_URL}/payments/${item.paymentId}`}>
                                  {item.paymentId}
                                </Link>
                              </span>
                            </td>
                            <td className="tb-tnx-info">
                              <div className="tb-tnx-desc">
                                <span className="title">{item.description}</span>
                              </div>
                              <div className="tb-tnx-date">
                                <span className="date">{formatDate(item.date, "DD-MM-YYYY")}</span>
                              </div>
                            </td>
                            <td className="tb-tnx-amount is-alt">
                              <div className="tb-tnx-total">
                                <span className="amount">
                                  {item?.planData?.currency} {item?.amountToShow}
                                </span>
                              </div>
                              <div className="tb-tnx-status">
                                <Badge
                                  className="badge-dot"
                                  color={
                                    item.status === "paid"
                                      ? "success"
                                      : item.status === "canceled"
                                      ? "danger"
                                      : "warning"
                                  }
                                >
                                  {item.status}
                                </Badge>
                              </div>
                            </td>
                            <td className="tb-odr-action">
                              <div className="tb-odr-btns d-none d-sm-inline">
                                {item.status === "pending" && (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setSelectedPayment(item);
                                      retryPayment(item.paymentId);
                                    }}
                                    disabled={retryingPayment}
                                  >
                                    {retryingPayment && selectedPayment?.paymentId === item.paymentId ? (
                                      <Spinner
                                        size="sm"
                                        color="dark"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                        }}
                                      />
                                    ) : (
                                      "Retry"
                                    )}
                                  </Button>
                                )}
                                {item.status === "pending" && (
                                  <Button
                                    color="danger"
                                    size="sm"
                                    className="btn btn-outline-danger"
                                    outline={true}
                                    onClick={() => {
                                      setSelectedPayment(item);
                                      cancelPayment(item.paymentId);
                                    }}
                                    disabled={cancelingPayment}
                                  >
                                    {cancelingPayment && selectedPayment?.paymentId === item.paymentId ? (
                                      <Spinner
                                        size="sm"
                                        color="dark"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                        }}
                                      />
                                    ) : (
                                      "Cancel"
                                    )}
                                  </Button>
                                )}
                              </div>
                              <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.paymentId}`}>
                                <Button className="btn-pd-auto d-sm-none">
                                  <Icon name="chevron-right"></Icon>
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            )}
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Payments;
