import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { logout, setOtpAttempts, setUserData } from "./redux/slices/UserSlice";
import { GetUpdatedProfile } from "./network/apis/auth";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { setLanguage } from "./redux/slices/LanguageSlice";
import EmailVerification from "./pages/auth/v2/EmailVerification";
import { useSelector } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { user, token, otp } = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setFetchingUserData(false);
    setIsEmailVerified(true);
    dispatch(
      setUserData({
        user: null,
      })
    );
    dispatch(logout());
    // Remove cookie
    document.cookie = `user=; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/;`;
  };

  useEffect(() => {
    // Change the language in Reduc state and update language and direction of the HTML document
    const i18nextLng = localStorage.getItem("i18nextLng");

    if (i18nextLng) {
      dispatch(
        setLanguage({
          language: i18nextLng,
          rtl: i18nextLng === "fa" ? true : false,
        })
      );
      // Set the language and direction of the document
      document.documentElement.lang = i18nextLng;
      document.documentElement.dir = i18nextLng === "fa" ? "rtl" : "ltr";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset OTP attempts
  useEffect(() => {
    // Check if lastAttempt is more than 30 minutes
    if (otp.lastAttempt) {
      const lastAttempt = new Date(otp.lastAttempt);
      const now = new Date();
      const diff = Math.abs(now - lastAttempt) / 60000; // Difference in minutes
      if (diff > 60) {
        dispatch(
          setOtpAttempts({
            otp: {
              attemptsLeft: 4,
              lastAttempt: null,
            },
          })
        );
      }
    } else {
      dispatch(
        setOtpAttempts({
          otp: {
            attemptsLeft: 4,
            lastAttempt: null,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.emailVerified) {
      setIsEmailVerified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getLoggedInUser = async () => {
    const res = await GetUpdatedProfile();

    if (res.result === "success") {
      const user = res.userData;

      if (res.userData.status?.toLowerCase() === "active") {
        setFetchingUserData(false);
        setIsLoggedIn(true);
        setIsEmailVerified(user.emailVerified);
        dispatch(
          setUserData({
            user: res.userData,
          })
        );
        // Set New user Cookie
        document.cookie = `user=${JSON.stringify({
          ...res.userData,
          photoURL: encodeURIComponent(res.userData.photoURL),
        })}; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/; max-age=604800;`;
      } else {
        setIsLoggedIn(false);
        setFetchingUserData(false);
        setIsEmailVerified(true);
        dispatch(
          setUserData({
            user: null,
          })
        );

        if (res.userData.status?.toLowerCase() === "suspended") {
          toast.warn("Your account has been suspended. Please contact support");
        } else {
          toast.warn("Your account is not active. Please contact support");
        }
        setTimeout(() => {
          dispatch(logout());
          navigator("/auth-login");
        }, 5000);
      }
    } else {
      setIsLoggedIn(false);
      setFetchingUserData(false);
      setIsEmailVerified(true);
      dispatch(
        setUserData({
          user: null,
        })
      );

      setTimeout(() => {
        dispatch(logout());
        navigator("/auth-login");
      }, 5000);
    }
  };

  useEffect(() => {
    if (token) {
      getLoggedInUser();
    } else {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ThemeProvider>
      {fetchingUserData ? (
        <>
          <section className="d-flex justify-content-center align-items-center vh-100">
            <Spinner size="lg" color="primary" />
          </section>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <Router authenticated={isLoggedIn} emailVerified={isEmailVerified} />
          ) : (
            <Router authenticated={false} emailVerified={false} />
          )}
        </>
      )}

      <Modal isOpen={user && !user?.emailVerified} size="md" toggle={() => {}}>
        <ModalBody className="modal-body-md">
          <EmailVerification />
        </ModalBody>
      </Modal>

      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
