import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  otp: {
    attemptsLeft: 4,
    lastAttempt: null,
  },
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload.user;
    },
    setOtpAttempts: (state, action) => {
      state.otp = action.payload.otp;
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setUserData, setOtpAttempts, logout, setLogin } = UserSlice.actions;
export default UserSlice.reducer;
