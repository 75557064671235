import React from "react";
import { Card, Col, Row } from "reactstrap";
import { Button, RSelect } from "../Component";
import { useDispatch } from "react-redux";
import { removeItemFromCart, updateItem } from "../../redux/slices/CartSlice";

function CartItem({ cartItem }) {
  const dispatch = useDispatch();

  const updateCartItem = ({ id, isBotIncluded, duration }) => {
    const updatedItem = {
      ...cartItem,
      isBotIncluded: isBotIncluded,
      duration,
    };

    dispatch(
      updateItem({
        id: id,
        item: updatedItem,
      })
    );
  };

  return (
    <section>
      <Card className="card-bordered sp-plan my-2">
        <Row className="no-gutters">
          <Col md="8">
            <div className="sp-plan-info card-inner">
              <Row className="gx-0 gy-3">
                <Col sm="12">
                  <div className="sp-plan-name">
                    <h6 className="title">{cartItem.title}</h6>
                    <p>{cartItem.description}</p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sp-plan-desc card-inner">
              <Row className="gx-1">
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Monthly Price </span>
                    {cartItem?.currency}{" "}
                    {cartItem?.currency === "IRR"
                      ? cartItem?.isBotIncluded
                        ? cartItem?.withBotTomansPrice
                        : cartItem?.withoutBotTomansPrice
                      : cartItem?.isBotIncluded
                      ? cartItem?.withBotUsdPrice
                      : cartItem?.withoutBotUsdPrice}
                  </p>
                </Col>
                {cartItem?.isBotOptionAvailable && (
                  <Col col="6" lg="3">
                    <section>
                      <span className="text-soft">Strategy Bot Access</span>
                      <RSelect
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onChange={(selectedOption) => {
                          updateCartItem({
                            id: cartItem.id,
                            isBotIncluded: selectedOption.value === "yes" ? true : false,
                            duration: cartItem?.duration ?? 1,
                          });
                        }}
                        defaultValue={[
                          {
                            value: cartItem?.isBotIncluded ? "yes" : "no",
                            label: cartItem?.isBotIncluded ? "Yes" : "No",
                          },
                        ]}
                        placeholder="Bot Access"
                      />
                    </section>
                  </Col>
                )}
                <Col col="6" lg="3">
                  <span className="text-soft">Duration</span>
                  <RSelect
                    options={[
                      {
                        label: "1 Month",
                        value: 1,
                      },
                      {
                        label: "3 Months",
                        value: 3,
                      },
                      {
                        label: "6 Months",
                        value: 6,
                      },
                      {
                        label: "12 Months",
                        value: 12,
                      },
                    ]}
                    onChange={(selectedOption) => {
                      updateCartItem({
                        id: cartItem.id,
                        isBotIncluded: cartItem.isBotIncluded,
                        duration: selectedOption?.value ?? 1,
                      });
                    }}
                    defaultValue={
                      cartItem.duration
                        ? [
                            {
                              label: `${cartItem.duration} ${cartItem.duration > 1 ? "Months" : "Month"}`,
                              value: cartItem.duration,
                            },
                          ]
                        : []
                    }
                    placeholder="Duration"
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="4">
            <div className="sp-plan-action card-inner">
              <h6 className="title">
                Total Price:{" "}
                <span className="mx-1">
                  {cartItem?.currency}{" "}
                  {cartItem?.currency === "IRR"
                    ? cartItem?.isBotIncluded
                      ? cartItem?.withBotTomansPrice * (cartItem?.duration ?? 1)
                      : cartItem?.withoutBotTomansPrice * (cartItem?.duration ?? 1)
                    : cartItem?.isBotIncluded
                    ? cartItem?.withBotUsdPrice * (cartItem?.duration ?? 1)
                    : cartItem?.withoutBotUsdPrice * (cartItem?.duration ?? 1)}
                </span>
              </h6>
              <div className="sp-plan-note text-md-center">
                <Button
                  className="btn btn-outline-danger"
                  onClick={() => {
                    dispatch(
                      removeItemFromCart({
                        id: cartItem.id,
                      })
                    );
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  );
}

export default CartItem;
