import { GetUpdatedProfile } from "./auth";
import { put } from "../../network/Config/apiService";

const defaultProfile = {
  name: "",
  displayName: "",
  dob: new Date().toISOString(),
  email: "",
  phone: "",
  address: "",
  address2: "",
  state: "",
  country: "",
};

// Get Profile Data
const GetUserData = async (userUuid) => {
  try {
    const res = await GetUpdatedProfile();

    const userData = res.userData;

    return {
      result: "success",
      message: "User data fetched Successfully",
      userData: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message,
      userData: null,
    };
  }
};

// Update user profile
const UpdateProfile = async (userUuid, data) => {
  try {
    await put("/user/profile", {
      address: data.address,
      address2: data.address2,
      country: data.country,
      state: data.state,
      dob: data.dob,
      fullName: data.name,
      displayName: data.displayName,
      tradingPlatformId: data.tradingPlatformId,
      phone: data.phone,
      photoURL: data.photoURL,
    });

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message,
      data: null,
    };
  }
};

// Update User Profile Image
const UpdateProfileImage = async (userUuid, image) => {
  return {
    result: "error",
    message: "Temporary disabled",
    data: null,
  };
};

export { defaultProfile, GetUserData, UpdateProfile, UpdateProfileImage };
